.row {
    display: flex;
}

.column {
    width: 100%;
    max-width: 20rem;
}

.img{
    max-height: 8rem;
    max-width: 8rem;
    height: auto;
    width: auto;
}

.primaryImg{  /* To be used with .img */
    max-height: 11rem;
    max-width: 11rem;
}

.primaryText{
    max-width: 11rem;
    width: 100%;
    background-color: #e2eeda;
    padding: 0.45rem;
    text-align: center;
    color: #749b6d;
    border-radius: 3px;
}

.imgsContainer{
    display: flex;
    flex-wrap: wrap;
    max-width: 29rem;  /* 2 x column width (incl. padding) - primary image */
}

.imgBox{
    height: 8rem;
    width: 8rem;
    border: 1px solid grey;
    border-radius: 0.25rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
    padding: 0.25rem;
}

.firstImgBox {  /* To be used with .imgBox */
    border: 3px solid #8bb96e;
}

.primaryImgBox{  /* To be used with .imgBox */
    height: 11rem;
    width: 11rem;
    border: 3px solid #8bb96e;
}

.imgButton {
    position: absolute;
    height: 1.2rem;
    width: 1.2rem;
    padding: 0.4rem;
    border-radius: 4px;
}

.imgButtons {
    display: none;
}

/* CSS magic to hide/show image buttons */
.imgBox:hover > .imgButtons, .imgButtons:hover {
    display: inline-block;
}

.button {
    text-transform: unset !important;
    font-size: 1.2rem;
}

.input {
    display: none;
}

.myselect {
    position: relative;
}

.mypopup {
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    width: 80%;
    padding: 10px;
    border-radius: 4px;
    background-color: #5d1022;
    z-index: 2;
    color: white
}

