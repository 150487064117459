.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
}



.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.mySwiper2 {
    height: 296px;
    margin-top: 2px;
    width: 100%;
}

/* Media query */
@media (min-width: 900px) {
    .mySwiper2 {
        max-width: 513px;
    }
}

.mySwiper {
    height: 100px;
    box-sizing: border-box;
}

.mySwiper img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mySwiper .swiper-slide {
    width: 100%;
    height: 100%;
    opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
}

.my-swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.my-swiper-slide {
    height: 100%;
}

.mySwiperr {
    height: 300px;
    box-sizing: border-box;
}

.mySwiperr img{
    object-fit: cover;

}

.mySwiperr .swiper-slide {
    height: calc((100% / 4) - 4px) !important;
    width: calc((100% / 2) - 4px) !important;
    opacity: 0.4;
}


.mySwiperr .swiper-slide-thumb-active {
    opacity: 1;
}