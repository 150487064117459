.input {
    display: none;
}

.button {
    text-transform: unset !important;
}

.info {
    padding-top: 0.3rem;
    font-size: 0.75rem;
}

.wrapper {
    position: relative;
    display: inline-block;
    white-space: nowrap;
}

.overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
    position: absolute;
    background-color: rgba(225, 225, 225, 0.5);
}

